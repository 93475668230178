import AuthContext from "../../context/authContext"
import { useState, useContext, useEffect } from "react";
import LoadingButton from "../../Components/UI/LoadButton/LoadButton";
import AuthService from "../../services/auth.service";
import UserService from "../../services/user.service";
import { validateEmail, validateLastName, validateFirstName } from "../../services/validationUtils";
import { useHistory } from 'react-router-dom';
import ConfirmModal from "../Users/ConfirmModal";
import useTitle from "../../hooks/useTitle";

export default function Profile() {
    const [userRole, setUserRole] = useState(null);
    const { user, updateUserContext } = useContext(AuthContext);
    useTitle("Profil użytkownika");

    //user zawiera dane zapisane w localstorage
    // id, email, firstName, lastName, roleName, token
    const [selectedRole, setSelectedRole] = useState(userRole);

    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        roleName: user.roleName,
    });

    const history = useHistory();

    const [isFormChanged, setIsFormChanged] = useState(false);

    const [errors, setErrors] = useState({
        email: '',
        lastName: '',
        firstName: '',
        selectedRole: '',
        registrationError: '',
    });

    //walidacja
    useEffect(() => {
        const newErrors = {
            email: validateEmail(formData.email) ? '' : 'Niepoprawny email',
            lastName: validateLastName(formData.lastName) ? '' : 'Nazwisko musi zawierać min. 3 znaki, max. 50',
            firstName: validateFirstName(formData.firstName) ? '' : 'Pole nie może być puste!',
            selectedRole: (selectedRole === '') ? 'Wybierz poprawną rolę' : '',
            registrationError: errors.registrationError,
        };

        setErrors(newErrors);
    }, [formData.email, formData.lastName, formData.firstName, selectedRole, errors.registrationError]);
    ////////////////////////////////


    useEffect(() => {
        // Sprawdzanie, czy aktualne dane formularza różnią się od danych pobranych z bazy
        const isDataChanged =
            formData.email !== user.email ||
            formData.firstName !== user.firstName ||
            formData.lastName !== user.lastName ||
            formData.roleName !== user.roleName;
        setIsFormChanged(isDataChanged);
    }, [formData, user]);

    const handleFieldChange = (fieldName, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [fieldName]: value,
        }));
    };


    useEffect(() => {
        setUserRole(AuthService.getRoleFromStorage());

    }, []);

    const cancelAction = (e) => {
        e.preventDefault();
        cancel();
    }

    const cancel = function () {
        setFormData(() => ({
            email: user.email,
            lastName: user.lastName,
            firstName: user.firstName,
            roleName: user.roleName
        }));
    }

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const showModal = (e) => {
        e.preventDefault();
        console.log("setShowConfirmModal")
        setShowConfirmModal(true);
    }

    const saveProfile = async (isConfirmed) => {

        if (!isConfirmed) {
            setShowConfirmModal(false);
            cancel();
            return;
        }

        setLoading(true);

        if (selectedRole === 1) {
            console.log("Zła rola")
        }
        const userData = {
            id: user.id,
            email: formData.email,
            firstName: formData.firstName,
            lastName: formData.lastName,
            rolename: formData.roleName // Dodanie wybranej roli
        };

        await UserService.updateUserBoard(userData)
            .then((response) => {

                if (response.status === 204) {
                    const newUser = {
                        ...user,
                        email: userData.email,
                        firstName: userData.firstName,
                        lastName: userData.lastName,
                        roleName: userData.rolename,
                    };
                    updateUserContext(newUser);

                    setLoading(false);
                    history.push('/home/profile')
                }

            }
            ).catch((error) => {
                setErrors({ ...errors, registrationError: error.response.data.error });
                setLoading(false);
            });
        setShowConfirmModal(false);
    };

    /////////////////////////////////////////
    const changePass = () => {
        history.push({
            pathname: '/home/profile/changepass',
            state: { email: user.email }
        });
    }
    /////////////////////////////////////////

    const renderFormGeneral = () => {
        return (
            <>
                <form>
                    <ConfirmModal show={showConfirmModal} handleConfirm={saveProfile} />
                    <div className="form-group mb-3">
                        <label>Email</label>

                        {errors.registrationError &&
                            <div className="alert alert-danger" role="alert">
                                <p>Błąd rejestracji: {errors.registrationError}</p>
                            </div>}

                        <input type="email"
                            value={formData.email}
                            onChange={(e) => handleFieldChange("email", e.target.value)}
                            className={`form-control ${errors.email ? 'is-invalid' : 'is-valid'}`} />
                        <div className="invalid-feedback">{errors.email}</div>
                    </div>

                    <div className="form-group mb-3">
                        <label>Nazwisko</label>
                        <input type="text"
                            value={formData.lastName}
                            onChange={(e) => handleFieldChange("lastName", e.target.value)}
                            className={`form-control ${errors.lastName ? 'is-invalid' : ''}`} />
                        <div className="invalid-feedback">{errors.lastName}</div>
                    </div>

                    <div className="form-group mb-3">
                        <label>Imię</label>
                        <input type="text"
                            value={formData.firstName}
                            onChange={(e) => handleFieldChange("firstName", e.target.value)}
                            className={`form-control ${errors.firstName ? 'is-invalid' : ''}`} />
                        <div className="invalid-feedback">{errors.firstName}</div>
                    </div>

                    <div className="form-group mb-3">
                        <label>Rola</label>
                        <select
                            value={formData.roleName} // Przechowuje wybraną rolę w stanie komponentu
                            onChange={(e) => {
                                setSelectedRole(e.target.value);
                                handleFieldChange("roleName", e.target.value);
                            }}
                            className={`form-control ${errors.selectedRole ? 'is-invalid' : ''}`}>

                            <option value="">Wybierz rolę</option>
                            {userRole?.map(role => (
                                <option key={role.role_id} value={role.rolename}>
                                    {role.rolename}
                                </option>
                            ))}
                        </select>
                        {errors.selectedRole && <div className="invalid-feedback">{errors.selectedRole}</div>}
                    </div>

                    <LoadingButton className={'primary card-link mt-2'} sm={'true'} loading={loading ? true : undefined}
                        disabled={!isFormChanged} onClick={(e) => { showModal(e) }}
                    >Zapisz</LoadingButton>

                    {isFormChanged &&
                        <button className="btn btn-danger card-link mt-2" onClick={cancelAction}>Anuluj</button>
                    }
                </form>
                <button className="btn btn-warning card-link mt-2" onClick={changePass}>Zmień hasło</button>
            </>
        );
    }

    ////////////////////////////////////////
    const renderFormUser = () => {
        return (
            <>
                <form>
                    <ConfirmModal show={showConfirmModal} handleConfirm={saveProfile} />
                    <div className="form-group mb-3">
                        <label>Email</label>

                        {errors.registrationError &&
                            <div className="alert alert-danger" role="alert">
                                <p>Błąd rejestracji: {errors.registrationError}</p>
                            </div>}

                        <input type="email"
                            value={formData.email}
                            onChange={(e) => handleFieldChange("email", e.target.value)}
                            className={`form-control ${errors.email ? 'is-invalid' : 'is-valid'}`} />
                        <div className="invalid-feedback">{errors.email}</div>
                    </div>


                    <LoadingButton className={'primary card-link mt-2'} sm={'true'} loading={loading ? true : undefined}
                        disabled={!isFormChanged} onClick={(e) => { showModal(e) }}
                    >Zapisz</LoadingButton>

                    {isFormChanged &&
                        <button className="btn btn-danger card-link mt-2" onClick={cancelAction}>Anuluj</button>
                    }
                </form>
                <button className="btn btn-warning card-link mt-2" onClick={changePass}>Zmień hasło</button>
                <ul className="list-group list-group-flush mt-3">
                    <li className="list-group-item">Nazwisko: {user.lastName}</li>
                    <li className="list-group-item">Imię: {user.firstName}</li>
                    <li className="list-group-item">Uprawnienia: {user.roleName}</li>
                </ul>
            </>
        );
    }

    /////////////////////////////////////////

    if (!userRole) {
        return (<h4>Problem</h4>);
    }
    ///////////////////////////////////////
    return (
        <div className="row justify-content-center">
            <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                <div className="card p-4">
                    <div className="card-body bg-primary-subtle">
                        <h4 className="card-title">Mój profil</h4>

                        {user.roleName === 'general_role' ? (
                            <div>
                                <h6 className="card-subtitle mb-2 text-body-secondary">Edytuj dane</h6>
                                {renderFormGeneral()}
                            </div>
                        )
                            :
                            (
                                <div>
                                    <h6 className="card-subtitle mb-2 text-body-secondary">Zmień e-mail</h6>
                                    {renderFormUser()}
                                </div>
                            )
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}