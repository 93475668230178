//import { useParams, useNavigate } from "react-router-dom";
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useState } from 'react';
import LoadingButton from "../../Components/UI/LoadButton/LoadButton";

export default function UserDeleteModal(props) {

    const [deleting, setDeleting] = useState(false);

    const handleDeleteClick = async (isConfirmed) => {
        setDeleting(true);
        await props.handleDelete(isConfirmed);
        setDeleting(false);
    };

    return (
        <div>
            <Modal show={props.show} onHide={props.handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Usuwanie użytkownika</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Czy usunąć użytkownika o numerze {props.userId}?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleDeleteClick(true)}
                        disabled={deleting}>
                        {deleting ? <LoadingButton className={'primary'} sm={true} loading={deleting ? true : undefined}/> : "Tak"}
                    </Button>
                    <Button variant="secondary" onClick={() => handleDeleteClick(false)}
                        disabled={deleting}>
                        Nie
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}