import './App.css';
import Header from './Components/Header/Header';
import Menu from './Components/Menu/Menu';
import Body from './Components/Body/Body';
import Layout from './Components/Layout/Layout';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Home from './Pages/Home/Home';
import NotFound from './Pages/NotFound/NotFound';
import { useEffect, useState } from 'react';
import Login from './Pages/Auth/Login';
import AuthService from './services/auth.service';
import UserService from './services/user.service';
import AuthContext from './context/authContext';
import AuthenticatedRoute from './hoc/AuthenticatedRoute';
import TimeOutModal from './Components/UI/TimeOutModal';
import useTitle from './hooks/useTitle';
import Calendar from './Components/Calendar/Calendar';
import CalendarBig from './Components/Calendar-big/CalendarBig';

function App() {
  //const [state, dispatch] = useReducer(reducer, initialState);

  const [userContx, setUserContx] = useState(AuthService.getCurrentUser());
  const [roles, setRoles] = useState(null);
  const [timeUntilLogout, setTimeUntilLogout] = useState(0);
  useTitle("Strona główna");

  useEffect(() => {

    if (userContx) {
      const tokenData = AuthService.getTokenData();
      if (tokenData) {
        const tokenExp = new Date(tokenData.exp * 1000);
        const currentTime = new Date();
        const timeUntilExpiration = tokenExp - currentTime;

        setTimeUntilLogout(timeUntilExpiration);
        console.log(timeUntilExpiration)
        if (timeUntilExpiration <= 0) {
          setShowModal(false);
          handleLogout();
        } else {
          const timeoutId = setTimeout(() => {  //po upływie timeUntilExpiration zostanie wykonana zawartość
            setShowModal(false);
            handleLogout();
          }, timeUntilExpiration);

          const intervalId = setInterval(() => {

            setTimeUntilLogout(prevTime => prevTime - 1000); // Odejmujemy 1000 ms (1 sekunda)
          }, 1000);

          return () => {
            clearTimeout(timeoutId); // Wyczyszczenie timeoutu przy odmontowaniu komponentu
            clearInterval(intervalId);
          }
        }
      }
    }
  }, [userContx]);

  const [showModal, setShowModal] = useState(false);

  //przedłuż sesję
  const handleClose = async () => {
    setShowModal(false);
    try {
      await AuthService.refreshTokenRequest();
      setUserContx(AuthService.getCurrentUser());
      setTimeUntilLogout(0);
    } catch (error) {
      console.error("Error refreshing token:", error);
    }
  }

  const handleLogoutModal = () => {
    setShowModal(false);
    handleLogout();
  }
  //const handleShow = () => setShowModal(true);

  const checkTimeUntilLogout = (timeUntilLogout) => {
    const roundedTime = Math.round(Math.max(timeUntilLogout, 0) / 1000);
    //console.log("roundedTime = ", roundedTime);
    if (roundedTime < 45 && roundedTime > 0) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  };

  useEffect(() => {
    checkTimeUntilLogout(timeUntilLogout);
  }, [timeUntilLogout]);

  const handleLogout = () => {
    AuthService.logout();
    setUserContx(null);
  };

  const loginHandler = async () => {
    //dispatch({ type: 'login', user: AuthService.getCurrentUser() });
    setUserContx(AuthService.getCurrentUser());
    const roleData = await UserService.getUsersRole();
    setRoles(roleData.data);
    if (roleData) localStorage.setItem("role-data", JSON.stringify(roleData.data));
  };

  const logoutHandler = () => {
    //dispatch({ type: 'logout' });
    AuthService.logout();
    setUserContx(null);
  };

  const updateUserContext = (newUserData) => {
    //console.log("Updating user context:", newUserData);
    localStorage.setItem("token-data", JSON.stringify(newUserData));
    setUserContx(newUserData);
  };

  const authContextValue = {
    user: userContx,
    roles: roles,
    login: loginHandler,
    logout: logoutHandler,
    updateUserContext: updateUserContext,
  };

  const header = <Header />
  const menu = <Menu />
  const body = (
    <div>
      <Switch>
        <Route exact path="/" component={Body} />
        <Route path="/login" component={Login} />
        <AuthenticatedRoute path="/home" component={Home} />
        <AuthenticatedRoute path="/calendar" component={Calendar} />
        <Route path="*" component={NotFound} />
      </Switch>
    </div>
  )

  return (
    <Router>
      <AuthContext.Provider value={authContextValue}>
        <div className="App">
          <Layout
            header={header}
            menu={menu}
            body={body}
          />
          <TimeOutModal show={showModal} handleClose={handleClose} handleLogout={handleLogoutModal} timeUntilLogout={timeUntilLogout} />
        </div>
      </AuthContext.Provider>
    </Router>
  );
}

export default App;
