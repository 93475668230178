import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function TimeOutModal(props) {

    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Przedłuż sesję</Modal.Title>
            </Modal.Header>
            <Modal.Body>Kliknij aby wylogować, lub przedłuż sesję. Czas do wylogowania:
                {Math.round(props.timeUntilLogout / 1000)} sec.</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleLogout}>
                    Wyloguj
                </Button>
                <Button variant="primary" onClick={props.handleClose}>
                    Przedłuż sesję
                </Button>
            </Modal.Footer>
        </Modal>
    );

}