import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CalendarService from '../../../services/calendar-service';
import { useEffect, useState } from 'react';
import LoadIcon from '../../UI/LoadIcon/LoadIcon';
import DetailsModal from './DetailsModal/DetailsModal';

const daysOfWeek = ['niedziela', 'poniedziałek', 'wtorek', 'środa', 'czwartek', 'piątek', 'sobota'];
const months = ['stycznia', 'lutego', 'marca', 'kwietnia', 'maja', 'czerwca', 'lipca', 'sierpnia', 'września', 'października', 'listopada', 'grudnia'];

export default function TimeOutModal(props) {
  const [loading, setLoading] = useState(true);
  const [koncert, setKoncert] = useState(null);
  const [show, setShow] = useState(false);

  const daneKoncert = async (idKoncert) => {
    try {
      const dane = await CalendarService.getConcert(idKoncert);

      setKoncert(dane.data[0]);
      console.log(dane.data[0]);
      console.log(koncert)
      setLoading(false);
    }
    catch (error) {
      console.log(error);
      throw error;
    }
  }

  useEffect(() => {
    setLoading(true);
    if (props.infoProba?.koncert_id) {
      //console.log(props.infoProba?.koncert_id)
      const idObj = { koncert_id: props.infoProba.koncert_id }
      daneKoncert(idObj)
    }
    else if(props.infoKoncert?.id){
      const idObj = { koncert_id: props.infoKoncert.id }
      daneKoncert(idObj)
    }
  }, [props.show]);
  //formatowanie daty na j. polski
  const dateEvent = (dateK) => {
    if (dateK === null) {
      return null;
    }
    let dateObject = new Date(dateK);
    const dayOfWeek = daysOfWeek[dateObject.getDay()];
    const dayOfMonth = dateObject.getDate();
    const month = months[dateObject.getMonth()];
    const year = dateObject.getFullYear();

    // Tworzymy sformatowany ciąg daty
    const formattedDate = `${dayOfWeek}, ${dayOfMonth} ${month} ${year}`;
    return formattedDate;

  }

  const getDetails = () => {
    setShow(true);
  }
  const handleClose = () => {
    setShow(false);
  }

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Plan na dzień:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? <LoadIcon />
            : <>

              {props.infoProba?.date &&
                <>
                  <h4>Próba:</h4>
                  <p><span className="fw-bold">Data próby:</span> {dateEvent(props.infoProba.date)}. <span className='fw-bold'>Godzina:</span> {props.infoProba?.hourBeg} - {props.infoProba?.hourEnd}</p>
                  <p><span className="fw-bold">Próba do koncertu w dniu:</span> {dateEvent(koncert.data)}, godz. {koncert.godzina.slice(0, -3)}</p>
                </>
              }

              {props.infoKoncert?.date &&
                <>
                  <h4>Koncert:</h4>
                  <p><span className="fw-bold">Data koncertu:</span> {dateEvent(props.infoKoncert.date)}</p>
                  <p><span className="fw-bold">Godzina:</span> {props.infoKoncert?.hour}</p>
                </>
              }

              <div className='list-group'>
                <button type="button" className="list-group-item list-group-item-action active text-center" aria-current="true" onClick={getDetails}>
                  Pokaż szczegóły
                </button>
              </div>
              <DetailsModal show={show} handleClose={handleClose} koncertId={props.infoProba?.koncert_id} koncertKId={props.infoKoncert?.koncert_id}/>
            </>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Zamknij
          </Button>
        </Modal.Footer>
      </Modal >
    </>
  );
}

