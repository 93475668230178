import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import AuthContext from "../../context/authContext";

function Menu() {
  const { user, logout } = useContext(AuthContext);

  const logOut = (e) => {
    e.preventDefault();
    logout();
  };

  return (
    <nav className="navbar navbar-expand-md bg-dark border-bottom border-body" data-bs-theme="dark">
      <div className="container-fluid">

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
          data-bs-target="#navbarNav" aria-controls="navbarNav"
          aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink exact to="/" className="nav-link" activeClassName="active">
                Główna
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/calendar" className="nav-link" activeClassName="active">
                Kalendarz
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/home" className="nav-link" activeClassName="active">
                Home
              </NavLink>
            </li>
            {user ? (
              <li className="nav-item">
                <a href="#" className="nav-link" onClick={logOut}>
                  Wyloguj
                </a>
              </li>
            ) : (
              <li className="nav-item">
                <NavLink to="/login" className="nav-link" activeClassName="active">
                  Zaloguj
                </NavLink>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Menu;
