import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

export default function LoadingButton(props){
    const className = props.className || 'primary';
    const sm = props.sm ? 'sm' : '';
    //console.log(`btn btn-${className} ${zzz} mt-4`)
    const loadingString = props.loading ? 'true' : 'false';
    return (
        props.loading ? (
            <Button variant={className} disabled>
            <Spinner
              as="span"
              animation="border"
              size={sm}
              role="status"
              aria-hidden="true"
            />
            <span className="visually-hidden">Loading...</span>
          </Button>
        )
        :
        <button {...props} className={`btn btn-${className}`} loading={loadingString}>{props.children}</button>
    );
}