import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "https://server.nascisz.pl:5000/";

const getConcertsData = (requestData) => {

  return axios.post(API_URL + "datakoncert", requestData, {headers: authHeader()});
};

const getProbaData = (requestData) => {
//console.log("GetProbadata")
  return axios.post(API_URL + "dataproby", requestData, {headers: authHeader()});
};

const getConcert = (requestData) => {
  return axios.post(API_URL + "koncert", requestData, {headers: authHeader()});
}

const getConcertDetails = (requestData) => {
  return axios.post(API_URL + "koncert/szczegoly", requestData, {headers: authHeader()});
}

const CalendarService = {
    //getPublicContent,
    getConcertsData,
    getProbaData,
    getConcert,
    getConcertDetails
  };

  export default CalendarService;