import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "https://server.nascisz.pl:5000/";

// const getPublicContent = () => {
//   return axios.get(API_URL + "all");
// };

const getUsersBoard = () => {
    //console.log(authHeader())
  return axios.get(API_URL + "users", { headers: authHeader() });
};

const getUserBoard = (id) => {
    return axios.get(API_URL + `users/${id}`, { headers: authHeader() });
  };

  const getUserBoard2 = (id) => {
    return axios.get(API_URL + `users2/${id}`, { headers: authHeader() });
  };

const delUserBoard = (id)=>{
  return axios.delete(API_URL + `users/${id}`, { headers: authHeader() });
};

const getUsersRole = () => {
  //console.log(authHeader())
return axios.get(API_URL + "role", { headers: authHeader() });
};

const addUserBoard = (userData)=>{
  return axios.post(API_URL + 'register', userData, { headers: authHeader() });
}

const updateUserBoard = (userData)=>{
  return axios.put(API_URL + 'profile/edit', userData, { headers: authHeader() } );
}

const updateUserPassword = (userData)=>{
  return axios.put(API_URL + 'profile/edit-password', userData, { headers: authHeader() } );
}

// const getModeratorBoard = () => {
//   return axios.get(API_URL + "mod", { headers: authHeader() });
// };

// const getAdminBoard = () => {
//   return axios.get(API_URL + "admin", { headers: authHeader() });
// };

const UserService = {
  //getPublicContent,
  getUsersBoard,
  getUserBoard,
  getUserBoard2,
  delUserBoard,
  getUsersRole,
  addUserBoard,
  updateUserBoard,
  updateUserPassword
//   getModeratorBoard,
//   getAdminBoard,
};

export default UserService;