//import useTitle from "../../hooks/useTitle";
import LoadingButton from "../../Components/UI/LoadButton/LoadButton";
import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import UserService from "../../services/user.service";
import { validateEmail, validatePassword, validateLastName, validateFirstName } from "../../services/validationUtils";

export default function UserAdd() {

    const [email, setEmail] = useState('');
    const [lastName, setLastName] = useState('');
    const [forName, setForName] = useState('');
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const [loading, setLoading] = useState(false);

    const [errors, setErrors] = useState({
        email: '',
        password: '',
        lastName: '',
        forName: '',
        selectedRole: '',
        registrationError: '',
        rePassword: ''
    });

    const [selectedRole, setSelectedRole] = useState('');

    const [roles, setRoles] = useState([]);

    const history = useHistory();

    const cleanForm = () => {
        const newError = {
            email: '',
            password: '',
            lastName: '',
            forName: '',
            selectedRole: '',
            registrationError: '',
            rePassword: ''
        }
        setErrors(newError);
        setEmail('');
        setLastName('');
        setForName('');
        setPassword('');
        setSelectedRole('1');
        setRePassword('');
    }

    //pobranie istniejących roli
    const fetchData = async () => {
        await UserService.getUsersRole()
            .then(response => {
                setRoles(response.data);
                //console.log("Role = ", roles)
                if (response.data.length > 0) {
                    setSelectedRole(response.data[0].role_id); // Ustaw domyślną rolę
                }
            })
            .catch(error => {
                console.error("Błąd pobierania ról:", error);
            });
    }

    useEffect(() => {
        fetchData();
    }, []);


    const saveProfile = async (e) => {
        //try {
        e.preventDefault();
        setLoading(true);

        if (selectedRole === 1) {
            console.log("Zła rola")
        }
        const userData = {
            email,
            password,
            firstName: forName,
            lastName,
            rolename: selectedRole // Dodanie wybranej roli
        };

        await UserService.addUserBoard(userData)
            .then((response) => {
                // Przetworzenie odpowiedzi
                console.log("response = ", response.status); // W razie powodzenia api zwraca kod 201

                setLoading(false);
                history.push('/home/users');
            }
            ).catch((error) => {
                setErrors({ ...errors, registrationError: error.response.data.error });
                setLoading(false);
            });
    };
    /////////////////////////////////////////
    //walidacja
    useEffect(() => {
        const newErrors = {
            email: validateEmail(email) ? '' : 'Niepoprawny email',
            password: (validatePassword(password)) ? '' : 'Hasło musi zawierać min. 4 znaki, max. 30. Duże i małe litery oraz znaki specjalne',
            lastName: validateLastName(lastName) ? '' : 'Nazwisko musi zawierać min. 3 znaki, max. 50',
            forName: validateFirstName(forName) ? '' : 'Pole nie może być puste!',
            selectedRole: (selectedRole === 1 || !selectedRole) ? 'Wybierz poprawną rolę' : '',
            registrationError: errors.registrationError,
            rePassword: rePassword === password ? '' : 'Błędnie powtórzone hasło'
        };

        setErrors(newErrors);
    }, [email, password, lastName, forName, selectedRole, errors.registrationError, rePassword]);

    const buttonDisabled = Object.values(errors).filter(x => x).length;

    return (

            <div className="row justify-content-center">
                <div className="col-lg-4">
                    <div className="card p-4">
                        <div className="card-body bg-primary-subtle">
                            <h4 className="card-title">Nowy użytkownik</h4>

                            <form onSubmit={saveProfile}>
                                <div className="form-group mb-3">
                                    <label>Email</label>

                                    {errors.registrationError &&
                                        <div className="alert alert-danger" role="alert">
                                            <p>Błąd rejestracji: {errors.registrationError}</p>
                                        </div>}

                                    <input type="email"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        className={`form-control ${errors.email ? 'is-invalid' : 'is-valid'}`} />
                                    <div className="invalid-feedback">{errors.email}</div>
                                </div>

                                <div className="form-group mb-3">
                                    <label>Hasło</label>
                                    <input type="password"
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                        className={`form-control ${errors.password ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.password}</div>
                                </div>

                                <div className="form-group mb-3">
                                    <label>Powtórz hasło</label>
                                    <input type="password"
                                        value={rePassword}
                                        onChange={e => setRePassword(e.target.value)}
                                        className={`form-control ${errors.rePassword ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.rePassword}</div>
                                </div>

                                <div className="form-group mb-3">
                                    <label>Nazwisko</label>
                                    <input type="text"
                                        value={lastName}
                                        onChange={e => setLastName(e.target.value)}
                                        className={`form-control ${errors.lastName ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.lastName}</div>
                                </div>

                                <div className="form-group mb-3">
                                    <label>Imię</label>
                                    <input type="text"
                                        value={forName}
                                        onChange={e => setForName(e.target.value)}
                                        className={`form-control ${errors.forName ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.forName}</div>
                                </div>

                                <div className="form-group mb-3">
                                    <label>Rola</label>
                                    <select
                                        value={selectedRole} // Przechowuje wybraną rolę w stanie komponentu
                                        onChange={e => setSelectedRole(e.target.value)}
                                        className={`form-control ${errors.selectedRole ? 'is-invalid' : ''}`}>

                                        <option value="">Wybierz rolę</option>
                                        {roles.map(role => (
                                            <option key={role.role_id} value={role.rolename}>
                                                {role.rolename}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.selectedRole && <div className="invalid-feedback">{errors.selectedRole}</div>}
                                </div>

                                <LoadingButton className={'primary'} sm={'true'} loading={loading ? true : undefined}
                                    disabled={buttonDisabled}
                                >Zapisz</LoadingButton>


                            </form>

                        </div>
                        {errors.registrationError &&
                            <div className="card-footer">
                                <span className="d-grid gap-2">
                                    <button className="btn btn-danger" onClick={cleanForm}>Anuluj</button>
                                </span>
                            </div>}
                    </div>
                </div>
            </div>

    );
}