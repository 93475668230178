import CalendarService from "../../../services/calendar-service";
import DateServices from "./dateServices";

const fetchConcertsData = async (dataZakres) => {
    try {
        const dane = await CalendarService.getConcertsData(dataZakres);
        //console.log(dane.data)
        ////////////////////////////////////////
        let events2 = [];

        const dataBeg = DateServices.modifiedDane(dataZakres.dataBeg);
        const dataEnd = DateServices.modifiedDane(dataZakres.dataEnd);

        let tabLiczKoncerty = [];
        let tabDaneKonc = [];

        const convBegin = DateServices.convertData(dataBeg);
        const parts = convBegin.split("-");
        const year = parts[0];
        const month = parts[1];

        //
        for (let currentDate = new Date(dataBeg); currentDate <= dataEnd; currentDate.setDate(currentDate.getDate() + 1)) {
            const formattedDate = DateServices.convertData(currentDate);
            let licznik = 0;

            dane.data.forEach(i => {
                if (i.data === formattedDate) {
                    ++licznik;
                    const obj = {
                        id: i.koncert_id,
                        hour: i.godzina.slice(0, -3)
                    }
                    tabDaneKonc.push(obj);
                }
            });
            tabLiczKoncerty.push(licznik);
        }
        //console.log(tabDaneKonc);

        //dodanie tytułu w zależności od ilości zajęć
        for (let i = 0; i < tabLiczKoncerty.length; i++) {
            if (tabLiczKoncerty[i] === 1) {
                const date = `${year}-${month}-${i + 1}`;
                //console.log("data2 = ", date)
                const title = "koncert";
                const obj = {
                    date,
                    title
                };
                events2.push(obj);
            }
            //console.log("events2 = ", events2)
            if (tabLiczKoncerty[i] > 1) {
                const date = `${year}-${month}-${i + 1}`;
                const title = `koncerty ${tabLiczKoncerty[i]}`;
                const obj = {
                    date,
                    title
                };
                events2.push(obj);
            }
        }


        const events3 = events2.map((el, index) => {
            if (tabDaneKonc[index]) {
                return { ...el, ...tabDaneKonc[index] };
            } else {
                return el;
            }
        });

        return events3;

    } catch (error) {
        console.log(error);
        throw error;
    }
}

export default fetchConcertsData;