import { useState, useEffect, useCallback } from "react";
import LoadingButton from "../../Components/UI/LoadButton/LoadButton";
import AuthService from "../../services/auth.service";
import UserService from "../../services/user.service";
import { validateEmail, validateLastName, validateFirstName } from "../../services/validationUtils";
import { useHistory } from 'react-router-dom';
import ConfirmModal from "../Users/ConfirmModal";
import useTitle from "../../hooks/useTitle";
import { useParams } from "react-router-dom";
import LoadingIcon from "../../Components/UI/LoadIcon/LoadIcon";

export default function UserDetailsPage() {
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(false);
  useTitle("Edycja użytkownika");
  const [user, setUser] = useState(null);
  const [formData, setFormData] = useState(null);
  const { userId } = useParams();
  const history = useHistory();

  // const fetchDataUser = async () => {
  //   //pobranie danych usera z bazy
  //   setLoading(true);
  //   await UserService.getUserBoard2(userId)
  //     .then(response => {
  //       setUser(response.data);
  //       setFormData(response.data);
  //       setLoading(false);
  //     })
  //     .catch(error => {
  //       console.error("Błąd pobierania:", error);
  //       setLoading(false);
  //       AuthService.logout();
  //       //<Redirect to="/login" />
  //       history.push("/login");
  //     });

  // }

  const fetchDataUser = useCallback(async () => {
    setLoading(true);
    try {
      const response = await UserService.getUserBoard2(userId);
      setUser(response.data);
      setFormData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Błąd pobierania:", error);
      setLoading(false);
      AuthService.logout();
      history.push("/login");
    }
  }, [userId, history]);

  useEffect(() => {
    setUserRole(AuthService.getRoleFromStorage());

  }, []);

  useEffect(() => {
    fetchDataUser();
  }, [fetchDataUser]);

  //user zawiera dane zapisane w localstorage
  // id, email, firstName, lastName, roleName, token
  const [selectedRole, setSelectedRole] = useState(userRole);

  const [isFormChanged, setIsFormChanged] = useState(false);

  const [errors, setErrors] = useState({
    email: '',
    lastName: '',
    firstName: '',
    selectedRole: '',
    registrationError: '',
  });

  //walidacja
  useEffect(() => {
    if (formData && user) {
      const newErrors = {
        email: validateEmail(formData.email) ? '' : 'Niepoprawny email',
        lastName: validateLastName(formData.lastName) ? '' : 'Nazwisko musi zawierać min. 3 znaki, max. 50',
        firstName: validateFirstName(formData.firstName) ? '' : 'Pole nie może być puste!',
        selectedRole: (selectedRole === '') ? 'Wybierz poprawną rolę' : '',
        registrationError: errors.registrationError,
      };

      setErrors(newErrors);
    }
  }, [formData?.email, formData?.lastName, formData?.firstName, selectedRole, errors.registrationError, formData, user]);
  ////////////////////////////////


  useEffect(() => {
    if (formData && user) {
      // Sprawdzanie, czy aktualne dane formularza różnią się od danych pobranych z bazy
      const isDataChanged =
        formData.email !== user.email ||
        formData.firstName !== user.firstName ||
        formData.lastName !== user.lastName ||
        formData.roleName !== user.roleName;
      setIsFormChanged(isDataChanged);
    }
  }, [formData, user]);

  const handleFieldChange = (fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };




  const cancelAction = (e) => {
    e.preventDefault();
    cancel();
  }

  const cancel = function () {
    setFormData(() => ({
      email: user.email,
      lastName: user.lastName,
      firstName: user.firstName,
      roleName: user.roleName
    }));
  }

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const showModal = (e) => {
    e.preventDefault();
    console.log("setShowConfirmModal")
    setShowConfirmModal(true);
  }

  const saveProfile = async (isConfirmed) => {

    if (!isConfirmed) {
      setShowConfirmModal(false);
      cancel();
      return;
    }

    setLoading(true);

    if (selectedRole === 1) {
      console.log("Zła rola")
    }
    const userData = {
      id: user.id,
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      rolename: formData.roleName // Dodanie wybranej roli
    };

    await UserService.updateUserBoard(userData)
      .then((response) => {

        if (response.status === 204) {
          const newUser = {
            ...user,
            email: userData.email,
            firstName: userData.firstName,
            lastName: userData.lastName,
            roleName: userData.rolename,
          };
          setUser(newUser);

          setLoading(false);
          //history.push('/home/users')
        }

      }
      ).catch((error) => {
        setErrors({ ...errors, registrationError: error.response.data.error });
        setLoading(false);
      });
    setShowConfirmModal(false);
  };

  const renderFormGeneral = () => {
    return (
      <>
        <form>
          <ConfirmModal show={showConfirmModal} handleConfirm={saveProfile} />
          <div className="form-group mb-3">
            <label>Email</label>

            {errors.registrationError &&
              <div className="alert alert-danger" role="alert">
                <p>Błąd rejestracji: {errors.registrationError}</p>
              </div>}

            <input type="email"
              value={formData.email}
              onChange={(e) => handleFieldChange("email", e.target.value)}
              className={`form-control ${errors.email ? 'is-invalid' : 'is-valid'}`} />
            <div className="invalid-feedback">{errors.email}</div>
          </div>

          <div className="form-group mb-3">
            <label>Nazwisko</label>
            <input type="text"
              value={formData.lastName}
              onChange={(e) => handleFieldChange("lastName", e.target.value)}
              className={`form-control ${errors.lastName ? 'is-invalid' : ''}`} />
            <div className="invalid-feedback">{errors.lastName}</div>
          </div>

          <div className="form-group mb-3">
            <label>Imię</label>
            <input type="text"
              value={formData.firstName}
              onChange={(e) => handleFieldChange("firstName", e.target.value)}
              className={`form-control ${errors.firstName ? 'is-invalid' : ''}`} />
            <div className="invalid-feedback">{errors.firstName}</div>
          </div>

          <div className="form-group mb-3">
            <label>Rola</label>
            <select
              value={formData.roleName} // Przechowuje wybraną rolę w stanie komponentu
              onChange={(e) => {
                setSelectedRole(e.target.value);
                handleFieldChange("roleName", e.target.value);
              }}
              className={`form-control ${errors.selectedRole ? 'is-invalid' : ''}`}>

              <option value="">Wybierz rolę</option>
              {userRole?.map(role => (
                <option key={role.role_id} value={role.rolename}>
                  {role.rolename}
                </option>
              ))}
            </select>
            {errors.selectedRole && <div className="invalid-feedback">{errors.selectedRole}</div>}
          </div>

          <LoadingButton className={'primary card-link mt-2'} sm={'true'} loading={loading ? true : undefined}
            disabled={!isFormChanged} onClick={(e) => { showModal(e) }}
          >Zapisz</LoadingButton>

          {isFormChanged &&
            <button className="btn btn-danger card-link mt-2" onClick={cancelAction}>Anuluj</button>
          }
        </form>
        {/* <button className="btn btn-warning card-link mt-2" onClick={changePass}>Zmień hasło</button> */}
      </>
    );
  }

  /////////////////////////////////////////

  if (!userRole) {
    return (<h4>Problem</h4>);
  }

  ///////////////////////////////////////
  return (
    <div className="row justify-content-center">
      <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
        <div className="card p-4">
          <div className="card-body bg-primary-subtle">
            <h4 className="card-title">Użytkownik</h4>
            <h6 className="card-subtitle mb-2 text-body-secondary">Edycja  danych</h6>

            {loading ? <LoadingIcon /> :
              renderFormGeneral()
            }

          </div>
        </div>
      </div>
    </div>
  )
}



// import React from "react";
// import { useParams } from "react-router-dom";
// import { useState, useEffect } from 'react';
// import LoadingIcon from "../../Components/UI/LoadIcon/LoadIcon";
// import { Link, Redirect } from 'react-router-dom';
// import UserService from "../../services/user.service";
// import AuthService from "../../services/auth.service";
// import UserEdit from "./UserEdit";
// import { useHistory } from 'react-router-dom';

// function UserDetailsPage({ user }) {
//   const history = useHistory();

//   const [userData, setUserData] = useState(null);
//   const [columnNames, setColumnNames] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const { userId } = useParams();

//   const fetchData = async () => {
//     //pobranie danych usera z bazy
//     await UserService.getUserBoard(userId)
//       .then(response => {
//         setUserData(response.data);
//         setColumnNames(Object.keys(response.data));
//         setLoading(false);
//       })
//       .catch(error => {
//         console.error("Błąd pobierania:", error);
//         setLoading(false);
//         AuthService.logout();
//         <Redirect to="/login" />
//       });

//   }

//   useEffect(() => {
//     fetchData();
//   },[]);

//   const handleEditUser = () => {
//     history.push({
//       pathname: `/home/users/${userId}/edit`,
//   state: { userData }
//   });
//   };

//   if (loading) {
//     return <LoadingIcon />
//   }
//   // if (!userData) {
//   //   return <div>Dane dostępne po zalogowaniu!</div>
//   // }

//   return (
//     <div className="container">
//       <div className="row justify-content-center">
//         <div className="col-sm-12 col-md-7 col-lg-5">
//           <div className="card">
//             <div className="card-body">
//               <h4 className="card-title">{userData.Imię} {userData.Nazwisko}</h4>
//               <div className="card-text">
//                 <div className="table-responsive-sm">
//                   <table className="table">
//                     <tbody>
//                       {columnNames.map((col, index) => (
//                         <tr key={index}>
//                           <td className="text-end">{col}: </td>
//                           <td className="text-start">{userData[col]}</td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>
//               </div>
//                         <button className="btn btn-danger card-link" onClick={handleEditUser}>Edytuj</button>
//               {/* <Link to={`/home/users/${userId}/edit`} className="card-link"
//               state={{userData: userData}}
//               >Edytuj dane</Link> */}
//               <Link to='/home/users' className="card-link">Powrót do tabeli</Link>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default UserDetailsPage;
