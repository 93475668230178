import './Calendar.css';
import { useEffect, useState } from 'react';
import CalendarHeader from './CalendarHeader/CalendarHeader';
import Day from './Day/Day';
import NewEventModal from './NewEventModal/NewEventModal';
import DeleteEventModal from './DeleteEventModal/DeleteEventModal';
import LoadingIcon from '../UI/LoadIcon/LoadIcon';
import fetchConcertsData from './Calendar-services/fetchCocertsData';
import fetchProbaData from './Calendar-services/fetchProbaData';
import AuthContext from '../../context/authContext';
import { useContext } from 'react';
import InfoModal from './InfoModal/InfoModal';

export default function Calendar() {
    const { logout } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [nav, setNav] = useState(0);
    const [clicked, setClicked] = useState(null);
    //const [events, setEvents] = useState([]);

    const pobierz = async () => {
        setLoading(true);

        const dt = new Date();
        if (nav !== 0) {
            dt.setMonth(new Date().getMonth() + nav);
        }
        // const dt = new Date();
        const month = dt.getMonth();
        const year = dt.getFullYear();
        const daysInMonth = new Date(year, month + 1, 0).getDate();

        const dataTmp = {
            dataBeg: `${year}-${month + 1}-1`,
            dataEnd: `${year}-${month + 1}-${daysInMonth}`
        }

        try {
            const eventy = await fetchConcertsData(dataTmp);
            //setEvents(eventy);
            //console.log(eventy);
            const eventyProba = await fetchProbaData(dataTmp);
            console.log(eventyProba);
            //setEventsProba(eventyProba);
            setLoading(false);
            return {
                eventy,
                eventyProba
            };
        } catch (error) {
            console.log(error);
            setLoading(false);
            return null;
        }
    }

    ///////////////////////////////////////////////////////
    //////////////////////////////////////////////////////
    const [dateDisplay, setDateDisplay] = useState('');
    const [days, setDays] = useState([]);



    function DateDisplayFun(navig, eventy, eventyProba) {

        const eventForDate = date => eventy.find(e => e.date === date);
        const eventForDateProba = date => eventyProba.find(e => e.date === date);

        const weekdays = ['poniedziałek', 'wtorek', 'środa', 'czwartek', 'piątek', 'sobota', 'niedziela'];
        const dt = new Date();
        if (navig !== 0) {
            dt.setMonth(new Date().getMonth() + navig);
        }

        const day = dt.getDate().toString().padStart(2, '0');;
        const month = (dt.getMonth() + 1).toString().padStart(2, '0');
        const year = dt.getFullYear();

        const firstDayOfMonth = new Date(year, month - 1, 1);
        const daysInMonth = new Date(year, month, 0).getDate();
        const dateString = firstDayOfMonth.toLocaleDateString('pl', {
            weekday: 'long',
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        });

        setDateDisplay(`${dt.toLocaleDateString('pl', { month: 'long' })} ${year}`);

        const paddingDays = weekdays.indexOf(dateString.split(', ')[0]);
        //console.log("padding = ", month)

        const daysArr = [];
        for (let i = 1; i <= paddingDays + daysInMonth; i++) {
            //const dayString = `${month + 1}.${i - paddingDays}.${year}`;
            const dayString = `${year}-${month}-${i - paddingDays}`;


            if (i > paddingDays) {

                daysArr.push({
                    value: i - paddingDays,
                    event: eventForDate(dayString),
                    eventProba: eventForDateProba(dayString),
                    isCurrentDay: i - paddingDays == day && nav === 0,
                    date: dayString,
                });
            } else {
                daysArr.push({
                    value: 'padding',
                    event: null,
                    eventProba: null,
                    isCurrentDay: false,
                    date: '',
                });
            }
        }
        setDays(daysArr);
    }
    /////////////////////////////////////////////////////


    useEffect(() => {
        console.log("UseEffect nav", nav)
        const fetchData = async () => {
        const res = await pobierz();
        //console.log(res.eventy)
        if(res) {
            DateDisplayFun(nav, res.eventy, res.eventyProba);
            //console.log(res.eventy)
        }
        else logout();
    };
    fetchData();

    }, [nav]);

    //console.log("odświeżam");

    //const eventForDate = date => events.find(e => e.date === date);

    // useEffect(() => {
    //     localStorage.setItem('events', JSON.stringify(events));
    // }, [events]);

    //const { days, dateDisplay } = useDate(events, nav); //zwraca
    const [showInfo, setShowInfo] = useState(false);
    const [koncertInfo, setKoncertInfo] = useState(null);
    const [probaInfo, setProbaInfo] = useState(null);

    const handleClose = () => {
        setShowInfo(false);
        setKoncertInfo(null);
        setProbaInfo(null);
    }

    return (
        <>
            <div id="container">
                <CalendarHeader
                    dateDisplay={dateDisplay}
                    onNext={() => setNav(nav + 1)}
                    onBack={() => setNav(nav - 1)}
                />

                <div id="weekdays">
                    <div>poniedziałek</div>
                    <div>wtorek</div>
                    <div>środa</div>
                    <div>czwartek</div>
                    <div>piątek</div>
                    <div>sobota</div>
                    <div>niedziela</div>
                </div>
                {loading ? <LoadingIcon />
                    :

                    <div id="calendar">
                        {days.map((d, index) => (
                            <Day
                                key={index}
                                day={d}

                                onClick={() => {
                                    if (d.value !== 'padding') {
                                        setClicked(d.date);
                                        if(d.event){
                                            setShowInfo(true);
                                            setKoncertInfo(d.event);
                                        }
                                        if(d.eventProba){
                                            setShowInfo(true);
                                            setProbaInfo(d.eventProba);
                                            console.log("eventProba = ", d.eventProba)
                                        }
                                    }
                                }}
                            />
                        ))}
                    </div>
                }
            </div>

            {
                clicked  &&
                <InfoModal show={showInfo} handleClose={handleClose} infoKoncert={koncertInfo} infoProba={probaInfo}/>
            }



            {/* {
                clicked && !eventForDate(clicked) &&
                <NewEventModal
                    onClose={() => setClicked(null)}
                    onSave={title => {
                        setEvents([...events, { title, date: clicked }]);
                        setClicked(null);
                    }}
                />
            }

            {
                clicked && eventForDate(clicked) &&
                <DeleteEventModal
                    eventText={eventForDate(clicked).title}
                    onClose={() => setClicked(null)}
                    onDelete={() => {
                        setEvents(events.filter(e => e.date !== clicked));
                        setClicked(null);
                    }}
                />
            } */}

        </>
    );
}