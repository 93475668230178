import CalendarService from "../../../services/calendar-service";
import DateServices from "./dateServices";

const fetchProbaData = async (dataZakres) => {

    try {
        const dane = await CalendarService.getProbaData(dataZakres);
        //console.log(dane);
        let events2 = [];

        const dataBeg = DateServices.modifiedDane(dataZakres.dataBeg);
        const dataEnd = DateServices.modifiedDane(dataZakres.dataEnd);

        let objLiczProby = [];
        let tabDaneProba = [];

        const convBegin = DateServices.convertData(dataBeg);
        const parts = convBegin.split("-");
        const year = parts[0];
        const month = parts[1];

        for (let currentDate = new Date(dataBeg); currentDate <= dataEnd; currentDate.setDate(currentDate.getDate() + 1)) {
            // resultArray.push(count);
            const formattedDate = DateServices.convertData(currentDate);
            //console.log("dane.data = ", dane.data)
            let licznik = 0;
            let rodzaj = '';
            dane.data.forEach(i => {
                if (i.data === formattedDate) {
                    rodzaj = i.rodzaj;
                    ++licznik;
                    const obj = {
                        id: i.proba_id,
                        hourBeg: i.godzina.slice(0, -3),
                        hourEnd: i.godzina_koniec.slice(0, -3),
                        koncert_id: i.koncert_id
                    }
                    tabDaneProba.push(obj);
                }
            });
            let obj = {
                licznik,
                rodzaj
            }
            objLiczProby.push(obj);
        }

        //console.log("objLiczProby = ", tabLiczProby)

        //dodanie tytułu w zależności od ilości zajęć
        for (let i = 0; i < objLiczProby.length; i++) {
            if (objLiczProby[i].licznik === 1) {
                const date = `${year}-${month}-${i + 1}`;
                let title = null;
                if(objLiczProby[i].rodzaj === 'WOLNE' || objLiczProby[i].rodzaj === 'ŚWIĘTO'){
                    title = `${objLiczProby[i].rodzaj}`;
                }
                else {
                    title = `próba ${objLiczProby[i].rodzaj}`;
                }
                //const title = objLiczProby[i].rodzaj === 'WOLNE' ? `${objLiczProby[i].rodzaj}` : `próba ${objLiczProby[i].rodzaj}`;
                const obj = {
                    date,
                    title
                };
                events2.push(obj);
            }
            //console.log("events2 = ", events2)
            if (objLiczProby[i].licznik > 1) {
                const date = `${year}-${month}-${i + 1}`;
                const title = `próby ${objLiczProby[i].licznik}`;
                const obj = {
                    date,
                    title
                };
                events2.push(obj);
            }
        }
        const events3 = events2.map((el, index) => {
            if (tabDaneProba[index]) {
                return { ...el, ...tabDaneProba[index] };
            } else {
                return el;
            }
        });

        //console.log("Events3 = ", events3)
        return events3;

    } catch (error) {
        console.log(error);
        throw error;
    }
}

export default fetchProbaData;