import { NavLink, Route, Switch, useRouteMatch } from "react-router-dom";
import Users from "../Users/Users";
import UserAdd from "../Users/UserAdd";
import UserDetailsPage from "../Users/UserDetailsPage";
import Profile from "../Profile/Profile";
import ChangePassword from "../Profile/ChangePassword";
import AuthContext from "../../context/authContext";
import { useContext } from "react";
import GeneralRoleRoute from "../../hoc/GeneralRoleRoute";

export default function Home() {
    let { path, url } = useRouteMatch();
    const { user } = useContext(AuthContext);

    return (
        <div className="container">
            <div className="card text-center">
                <div className="card-body">
                    <nav className="nav nav-tabs ">
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/home/profile">Mój profil</NavLink>
                        </li>
                        {user.roleName === 'general_role' &&
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/home/users">Użytkownicy</NavLink>
                        </li>
                        }

                    </nav>
                    <div className="pt-4">
                        <Switch>
                            <Route exact path={path}>
                                <h4>Wybierz pozycję z menu.</h4>
                            </Route>
                            <GeneralRoleRoute exact path={`${url}/users`} component={Users} />
                            <GeneralRoleRoute path='/home/users/new' component={UserAdd} />
                            {/* <GeneralRoleRoute path="/home/users/:userId/edit" component={UserEdit} /> */}
                            {/* <GeneralRoleRoute path="/home/users/:userId/edit" component={UserEdit} /> */}
                            <GeneralRoleRoute path="/home/users/:userId" component={UserDetailsPage} />


                            <Route path='/home/profile/changepass' component={ChangePassword} />
                            <Route path='/home/profile' component={Profile} />
                        </Switch>
                    </div>

                </div>
            </div>
        </div>
    )
}