
function modifiedDane(dat) {
    const parts = dat.split("-");
    const year = parseInt(parts[0]);
    const month = parseInt(parts[1]) - 1; // Odejmujemy 1, ponieważ miesiące są liczone od 0 do 11
    const day = parseInt(parts[2]);
    return new Date(year, month, day);
}

function convertData(dat) {
    //console.log("Convert: ", dat)
    const year = dat.getFullYear();
    const month = (dat.getMonth() + 1).toString().padStart(2, '0'); // Dodajemy +1, bo miesiące są liczone od 0 do 11
    const day = dat.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}

const DateServices = {
    modifiedDane,
    convertData
}

export default DateServices;