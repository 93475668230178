import React from 'react';

const AuthContext = React.createContext({
  user: null,
  roles: null,
  login: () => {},
  logout: () => {},
  updateUserContext: () => {},
});

AuthContext.displayName = 'AuthContext';

export default AuthContext;