
export default function LoadingIcon(props) {

  const loadingTextStyle = {
    fontSize: '10px', // Dostosuj wielkość czcionki według potrzeb
  };

  return (
    <div className="d-flex justify-content-center">
      <div className={`spinner-border text-dark`} role="status">
      <span className="visually-hidden" style={loadingTextStyle}>Loading...</span>
      </div>
    </div>
  );
}