import AuthService from "../../services/auth.service";
import UserService from "../../services/user.service";
import { useEffect, useState, useContext } from "react";
import { validatePassword, } from "../../services/validationUtils";
import LoadingButton from "../../Components/UI/LoadButton/LoadButton";
import { useLocation } from 'react-router-dom';
import AuthContext from "../../context/authContext";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function MyVerticallyCenteredModal(props) {
    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Zmiana hasła
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{props.message}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Wyloguj</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default function ChangePassword() {
    const context = useContext(AuthContext);

    const [modalShow, setModalShow] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const [loading, setLoading] = useState(false);

    const [errors, setErrors] = useState({
        password: '',
        rePassword: '',
        registrationError: ''
    });

    const cleanForm = () => {
        setOldPassword('');
        setPassword('');
        setRePassword('');
        setErrors({
            password: '',
            rePassword: '',
            registrationError: ''
        })
    }

    const location = useLocation();
    //console.log(location.state.email)

    useEffect(() => {
        const newErrors = {
            password: (validatePassword(password)) ? '' : 'Hasło musi zawierać min. 4 znaki, max. 30. Duże i małe litery oraz znaki specjalne',
            registrationError: errors.registrationError,
            rePassword: rePassword === password ? '' : 'Błędnie powtórzone hasło'
        };

        setErrors(newErrors);
    }, [password, errors.registrationError, rePassword]);

    const buttonDisabled = Object.values(errors).filter(x => x).length;

    const setLogout = () => {
        setModalShow(false);
        context.logout();

    }

    const handleChangePass = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const res = await AuthService.testPassword(location.state.email, oldPassword);
            console.log(res);
            if (res === "OK") {
                const result = await UserService.updateUserPassword({
                    id: context.user.id,
                    password: password
                });
                //console.log("result = ", result.status);
                if (result.status === 204) {
                    setModalMessage("Hasło zostało zmienione");
                    setModalShow(true);
                } else {
                    setModalMessage("Nieznany błąd podczas zmiany hasła.");
                    setModalShow(true);
                }
            }
            setLoading(false);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setErrors({ ...errors, registrationError: "Niepoprawne stare hasło!" });
            } else {
                setErrors({ ...errors, registrationError: error.message });
            }
            setLoading(false);
        }
    };

    return (
        <>
            <div className="row justify-content-center">
                <div className="col-lg-4">
                    <div className="card p-4">
                        <div className="card-body bg-primary-subtle">
                            <h4 className="card-title">Zmiana hasła</h4>

                            {errors.registrationError &&
                                <div className="alert alert-danger" role="alert">
                                    <p>Błąd: {errors.registrationError}</p>
                                </div>}

                            <form onSubmit={handleChangePass}>
                                <div className="form-group mb-3">
                                    <label>Wpisz stare hasło</label>
                                    <input type="password"
                                        value={oldPassword}
                                        onChange={e => setOldPassword(e.target.value)}
                                        className='form-control' />
                                </div>

                                <div className="form-group mb-3">
                                    <label>Nowe hasło</label>
                                    <input type="password"
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                        className={`form-control ${errors.password ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.password}</div>
                                </div>

                                <div className="form-group mb-3">
                                    <label>Powtórz nowe hasło</label>
                                    <input type="password"
                                        value={rePassword}
                                        onChange={e => setRePassword(e.target.value)}
                                        className={`form-control ${errors.rePassword ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.rePassword}</div>
                                </div>

                                <LoadingButton className={'primary'} sm={'true'} loading={loading ? true : undefined}
                                    disabled={buttonDisabled}
                                >Zapisz</LoadingButton>
                            </form>

                            {errors.registrationError &&
                                <div className="card-footer">
                                    <span className="d-grid gap-2">
                                        <button className="btn btn-danger" onClick={cleanForm}>Anuluj</button>
                                    </span>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
            <MyVerticallyCenteredModal
                show={modalShow}
                message={modalMessage}
                onHide={setLogout}
            />
        </>
    );
}