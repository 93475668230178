import { useState, useContext } from "react";
import { useHistory } from 'react-router-dom';

import AuthService from "../../services/auth.service";
import LoadingButton from "../../Components/UI/LoadButton/LoadButton";
import AuthContext from "../../context/authContext";

export default function Login() {

    const auth = useContext(AuthContext);
    //console.log("Login auth = ", auth)

    const history = useHistory();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const onChangeUsername = (e) => {
        const Email = e.target.value;
        setEmail(Email);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const handleLogin = async (e) => {
        e.preventDefault();
        setMessage("");
        setLoading(true);
        AuthService.login(email, password).then(
            () => {
                auth.login(); // Akualizacja stanu uwierzytelnienia w kontekście
                history.push("/home");
                //window.location.reload();
            }
        ).catch(
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setLoading(false);
                setMessage(resMessage);
            }
        )
    }

    if (auth.user) {
        return (history.push("/"));
    }

    return (
        <div className="row justify-content-center align-items-center mt-4">
            <div className="col-sm-2 col-lg-4"></div>
            <div className='col-sm-8 col-lg-4 text-center'>
                <div className="card border border-3">
                    <div className="card-body">
                        <h3 className="card-title">Logowanie</h3>

                        <form onSubmit={handleLogin}>
                            <div className="mb-3">
                                <label htmlFor="InputEmail" className="form-label">Adres email</label>
                                <input type="email"
                                    className="form-control"
                                    name="InputEmail"
                                    value={email}
                                    onChange={onChangeUsername}

                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="InputPassword" className="form-label">Hasło</label>
                                <input type="password"
                                    className="form-control"
                                    name="InputPassword"
                                    value={password}
                                    onChange={onChangePassword}
                                />
                            </div>

                            {message && (
                                <div className="form-group">
                                    <div className="alert alert-danger" role="alert">
                                        {message}
                                    </div>
                                </div>
                            )}

                            <LoadingButton loading={loading}
                                disabled={false}
                            >Zaloguj</LoadingButton>
                        </form>
                    </div>
                </div>

            </div>
            <div className="col-sm-2 col-lg-4"></div>
        </div>
    )
}