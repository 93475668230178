import styles from './Header.module.css';

function Header() {
    //<header className={`${styles.header}`}>
    return (
        <header className={`${styles.header}`}>
            <h2>Baza koncertów Filharmonii Podkarpackiej
                <br></br>
                <h4>im. Artura Malawskiego w Rzeszowie</h4>
            </h2>
        </header>
        // <header className="container fluid ">
        //     <div className='row justify-content-center align-items-center p-5'>
        //     <div className='col xs={12} text-center'>
        //         <h2>Baza koncertów filharmonii</h2>
        //         </div>
        //     </div>
        // </header>
    );
}

export default Header;