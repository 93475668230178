import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "https://server.nascisz.pl:5000/";

const register = (username, email, password) => {
  return axios.post(API_URL + "signup", {
    username,
    email,
    password,
  });
};

const login = async (email, password) => {
  return await axios
    .post(API_URL + "login", {
      email,
      password,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("token-data", JSON.stringify(response.data));
      }
      if(response.data.refreshToken){
        localStorage.setItem("refresh-token", JSON.stringify(response.data.refreshToken));
      }

      return response.data;
    });
};

const refreshTokenRequest = async () => {
  //wysłanie refresh token
  //console.group("refreshTokenRequest")
  const refreshToken = JSON.parse(localStorage.getItem("refresh-token"));
  const dataUser = JSON.parse(localStorage.getItem("token-data"));
  if(!refreshToken){
    throw new Error("Brak tokenu refreshToken");
  }
  try {
    const response = await axios.post(API_URL + 'refresh-token', {
      token: refreshToken
    });
    const newAccessToken = response.data.token;
    dataUser.token = newAccessToken;
    localStorage.setItem("token-data", JSON.stringify(dataUser));
  } catch (error) {
    console.error("Error refreshing token:", error);
    throw error;
  }
}

const testPassword = async (email, password) => {
  return await axios
    .post(API_URL + "testpass", {
      email,
      password,
    }, { headers: authHeader() })
    .then((response) => {
       return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("token-data");
  localStorage.removeItem("role-data");
  localStorage.removeItem("refresh-token");
};

const getCurrentUser = () => {
  //console.log("GetCurrent user")
  return JSON.parse(localStorage.getItem("token-data"));
};

const getTokenData = ()=>{
  const token = JSON.parse(localStorage.getItem("token-data"));
  if(token){
    return JSON.parse(atob(token.token.split(".")[1]));
  }
  else{
    return null;
  }
};

const getRoleFromStorage = ()=>{
  const role = JSON.parse(localStorage.getItem("role-data"));

  if(role){
    return role;
  }
  else return null;
}

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  getTokenData,
  getRoleFromStorage,
  testPassword,
  refreshTokenRequest
};

export default AuthService;