import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthContext from '../context/authContext';

const AuthenticatedRoute = (props) => {
  const { user } = useContext(AuthContext);

  return user ?
    <Route {...props} />
    : <Redirect to="/login"/>
};

export default AuthenticatedRoute;


