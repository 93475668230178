import { useEffect, useState } from "react";
import LoadingIcon from "../../Components/UI/LoadIcon/LoadIcon";
import { Container, Row, Col } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import UserDeleteModal from "./UserDeleteModal";
import UserService from "../../services/user.service";
import AuthService from "../../services/auth.service";
import { useHistory } from 'react-router-dom';
import useTitle from "../../hooks/useTitle";

//import styles from './Users.module.css';

export default function Users() {
useTitle("Użytkownicy");
  const [users, setUsers] = useState([]);
  const [columnNames, setColumnNames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [del, setDel] = useState(false);

  const history = useHistory();

  const setShowUser = () => {
    if (selectedUserId) {
      //window.location.href = `/home/users/${selectedUserId}`;
      history.push(`/home/users/${selectedUserId}`);
    }
  }

  const handleDeleteUser = async (isConfirmed) => {
    if (!isConfirmed) {
      setShowDeleteModal(false);
      setSelectedUserId(null);
      return;
    }

    await UserService.delUserBoard(selectedUserId)
      .then(response => {
        if (response.status === 204) {
          fetchData();
        }
        else {
          throw new Error("Nieznany błąd!")
        }
      })
      .catch(error => {
        window.alert(`Błąd usuwania: ${error}`);
      });

    setShowDeleteModal(false)
  }

  // Funkcja do obsługi podwójnego kliknięcia w wierszu
  const handleRowDoubleClick = (userId) => {
    window.location.href = `/home/users/${userId}`;
  };

  const [userRoleTest, setUserRoleTest] = useState(null);
  const handleRowClick = (user) => {
    setSelectedUserId(user.ID === selectedUserId ? null : user.ID);

    if (user.roleName === 'general_role') {
      setUserRoleTest('general_role')
    }
    else {
      setUserRoleTest('');
      setDel(false);
    }
  };

  const fetchData = async () => {

    await UserService.getUsersBoard().then(
      (response) => {
        setUsers(response.data);
        setColumnNames(Object.keys(response.data[0] || {}));
        setSelectedUserId(null);
        setLoading(false);
      }
    ).catch(
      (error) => {
        //context.logout();
        setLoading(false);
        AuthService.logout();
        <Redirect to="/login" />
        //console.error("Błąd pobierania:", error);
      }
    )
  }

  useEffect(() => {
    fetchData();
  }, []);


  const isGeneralRole = () => {
    if (userRoleTest === 'general_role') {
      setDel(true);
      setShowDeleteModal(false);
    }
    else {
      setDel(false);
      setShowDeleteModal(true);
    }
  }

  const renderTable = () => {
    return (
      <div className="table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl table-responsive-xxl">
        <table className="table table-striped table-bordered border-primary">
          <thead>
            <tr>
              <th scope="col">Lp.</th>
              {columnNames.map(columnName => (
                columnName !== "roleName" && (
                  <th key={columnName} scope="col">{columnName}</th>
                )
              ))}
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {users.map((user, index) => (
              <tr key={user.ID} onDoubleClick={() => handleRowDoubleClick(user.ID)}
                onClick={() => handleRowClick(user)}
                style={{ cursor: 'pointer' }}>

                <th style={{ backgroundColor: selectedUserId === user.ID ? 'yellow' : 'transparent' }} scope="row">{index + 1}</th>
                <td style={{ backgroundColor: selectedUserId === user.ID ? 'yellow' : 'transparent' }}>{user.ID}</td>
                <td style={{ backgroundColor: selectedUserId === user.ID ? 'yellow' : 'transparent' }}>{user.Email}</td>
                <td style={{ backgroundColor: selectedUserId === user.ID ? 'yellow' : 'transparent' }}>{user.Imię}</td>
                <td style={{ backgroundColor: selectedUserId === user.ID ? 'yellow' : 'transparent' }}>{user.Nazwisko}</td>
              </tr>
            ))}
          </tbody>

        </table>
      </div>
    );
  }


  if (loading) {
    return <LoadingIcon />
  }

  return (
    <Container>
      <Row className="justify-content-center">
        <Col sm={12} md={7}>
          <div className="card">
            <div className="card-body" style={{ padding: '10px' }}>
              <h4 className="card-title mb-1">Użytkownicy</h4>
              <h5 className="card-subtitle text-body-secondary mb-3">
                <span style={{ fontSize: '12px', fontStyle: 'italic' }}>(Zaznacz wiersz tabeli by usunąć użytkownika)</span></h5>

              <div className="d-flex flex-column flex-md-row justify-content-md-between align-items-center">
                <Link to='/home/users/new' className="btn btn-success card-link mb-2 mb-md-0 mx-auto">Dodaj</Link>

                {selectedUserId !== null && (
                  <>
                    <button onClick={isGeneralRole} className="btn btn-danger card-link mb-2 mb-md-0 mx-auto">
                      Usuń
                    </button>
                    <button onClick={setShowUser} className="btn btn-warning card-link mb-2 mb-md-0 mx-auto">
                      Szczegóły
                    </button>
                    {del &&
                      <div className="alert alert-danger mt-3" role="alert">
                        <p>Nie można usunąć użytkownika o roli "general_role"</p>
                      </div>
                    }
                  </>
                )}
              </div>

              <UserDeleteModal userId={selectedUserId} show={showDeleteModal} handleDelete={handleDeleteUser} />
              <div className="card-text mt-4">
                {renderTable()}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}