import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CalendarService from '../../../../services/calendar-service';
import { useEffect, useState } from 'react';
import LoadingIcon from '../../../UI/LoadIcon/LoadIcon';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

export default function DetailsModal(props) {

  const [loading, setLoading] = useState(true);
  const [koncert, setKoncert] = useState(null);

  const daneKoncertSzczeg = async (idKoncert) => {
    try {
      const dane = await CalendarService.getConcertDetails(idKoncert);

      setKoncert(dane.data[0]);
      //console.log(dane.data[0]);
      //console.log(koncert)
      setLoading(false);
    }
    catch (error) {
      console.log(error);
      throw error;
    }
  }

  useEffect(() => {
    setLoading(true);
    if (props?.koncertId) {
      //console.log("Koncert id = ", props?.koncertId)
      const idObj = { koncert_id: props?.koncertId }
      daneKoncertSzczeg(idObj);
    }
    else if (props?.koncertKId) {
      //console.log("Koncert Kid = ", props?.koncertKId);
      const idObj = { koncert_id: props?.koncertKId }
      daneKoncertSzczeg(idObj);
    }
  }, [props.show]);

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
        fullscreen={true}
      >
        <Modal.Header className='bg-primary text-white' closeButton>
          <Modal.Title>Informacje o koncercie</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? <LoadingIcon /> :
            <div className='container'>
              <Tabs
                defaultActiveKey="koncerty"
                id="fill-tab-example"
                className="mb-3"
                fill
              >
                <Tab eventKey="koncerty" title="Koncerty">
                  <div className='container'>
                    <div className='row mt-3'>
                      <div className='col border'>
                        <div className='card mb-2 text-center'>
                          <div className="card-header">
                            Miejsce koncertu
                          </div>
                          <div className="card-body">
                            <p className="card-text"><b>Sala:</b> Filharmonia</p>
                            <p className="card-text"><b>Miasto:</b> Rzeszów</p>
                          </div>
                        </div>
                        <div className='card'>
                          <div className="card-header">
                            Miejsce koncertu
                          </div>
                          <div className="card-body text-start">
                            <h5 className="card-title">Special title treatment</h5>
                            <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                          </div>
                        </div>
                      </div>
                      <div className='col border'>
                        <p className='text-center'><b>Główny wykonawca</b></p>
                      </div>
                      <div className='col border'>
                        <p className='text-center'><b>Skład orkiestry</b></p>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="dyrygenci" title="Dyrygenci">
                  Tab content for Profile
                </Tab>
                <Tab eventKey="solisci" title="Soliści">
                  Tab content for Loooonger Tab
                </Tab>
                <Tab eventKey="utwory" title="Utwory">
                  Tab content for Contact
                </Tab>
                <Tab eventKey="muzycy" title="Muzycy">
                  Tab content for Contact
                </Tab>
                <Tab eventKey="angaz" title="Muzycy doang.">
                  Tab content for Contact
                </Tab>
              </Tabs>

            </div>
          }

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Zamknij
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}