import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../context/authContext';
import useTitle from '../../hooks/useTitle';

function Body() {
    useTitle("Strona główna");
    console.log("Body")
    const { user } = useContext(AuthContext);
    //const state = useReducer(ReducerContext);
    const [title, setTitle] = useState("Strona główna");
    //const nameContext = useContext(NameContext);
    //const context = useContext(AuthContext);

    useEffect(()=>{
        if(user){
            setTitle(`Witaj ${user.firstName}`);
        }else {
            setTitle("Strona główna");
        }

    },[user])


    return (
        <div className="card mt-2 text-center bg-secondary-subtle" >
            <div className="card-body">
                <h5 className="card-title">{title}</h5>
                <h6 className="card-subtitle mb-2 text-body-secondary">Interfejs bazy danych</h6>
                <p className="card-text">Aplikacja służąca do przeglądu i obsługi bazy koncertów i wydarzeń artystycznych
                <br></br>Filharmonii Podkarpackiej.</p>
                {/* <button className='btn btn-primary' onClick={zmien} disabled={disabled}>Zmień</button> */}
            </div>

        </div>
    );
}

export default Body;