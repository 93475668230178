export default function CalendarHeader({ onNext, onBack, dateDisplay }) {
    return(
        <div id="header">
            <div id="monthDisplay">{dateDisplay}</div>
      <div>
        <button className="button" onClick={onBack} id="backButton">Poprzedni</button>
        <button className="button" onClick={onNext} id="nextButton">Następny</button>
      </div>
        </div>
    );
}