export function validatePassword(pass) {
    if (!pass) {
        return false;
    }
    // Długość hasła od 4 do 30 znaków
    if (pass.length < 4 || pass.length > 30) {
        return false;
    }

    // Przynajmniej jedna duża litera
    if (!/[A-Z]/.test(pass)) {
        return false;
    }

    // Przynajmniej jedna mała litera
    if (!/[a-z]/.test(pass)) {
        return false;
    }

    // Przynajmniej jeden znak specjalny
    if (!/[!@#$%^&*()_+{}\\[\]:;<>,.?~\\/\\-]/.test(pass)) {
        return false;
    }

    return true;
}

export function validateEmail(text) {
    var re = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
    return re.test(text);
}

export function validateLastName(lastName) {
    return lastName.length > 2 && lastName.length < 50;
}

export function validateFirstName(forName) {
    return forName.trim().length > 0;
}