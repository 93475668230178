import { Calendar, momentLocalizer } from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from 'moment/moment';
import "moment/locale/pl";
import { useState } from 'react';
import styles from './CalendarBig.module.css';

const localizer = momentLocalizer(moment);

const events = [
    {
      title: "koncerty",
      allDay: true,
      start: new Date(2023, 8, 25),
      end: new Date(2023, 8, 25)
    }
];

export default function CalendarBig () {
    const [newEvent, setNewEvent] = useState({title: "", start: "", end: ""});
  const [allEvents, setAllEvents] = useState(events);
  const [selected, setSelected] = useState();
  const handleSelected = (event) => {
    setSelected(event);
    console.info('[handleSelected - event]', event);
  };
  return (
    <div className={`container ${styles.calendarBigContainer}`}>
    <div className='row'>
      <Calendar localizer={localizer}
        events={allEvents}
        startAccessor={"start"}
        endAccessor={"end"}
        style={{ height: "90vh", width: "100%", margin: "50px" }}
        onSelectEvent={handleSelected}
        resizable={true}
        selectable
        messages={{
                    next: "następny",
                    previous: "poprzedni",
                    today: "dzisiaj",
                    month: "Miesiąc",
                    week: "Tydzień",
                    day: "dzień"
                  }}
         />
         </div>
    </div>
  );
}