import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthContext from '../context/authContext';

const GeneralRoleRoute = (props) => {
  const { user } = useContext(AuthContext);

  return user.roleName === 'general_role' ?
    <Route {...props} />
    : <Redirect to="/home/profile"/>
};

export default GeneralRoleRoute;


